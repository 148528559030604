<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_main_block_top">
                <div class="admin_main_block_left">
                    <!-- <div><router-link to="/Admin/menus/form"><el-button type="primary" icon="el-icon-plus">添加</el-button></router-link></div> -->
                    
                   <div><el-input v-model="where.F_VR_NIC" placeholder="请输入作者名称"></el-input></div>
                    <!-- <div style="width:130px;"><el-date-picker format="yyyy-MM-dd HH:mm" type="date" placeholder="开始日期" v-model="where.times[0]" style="width: 100%;"></el-date-picker></div> -->
                    <div><el-date-picker format="yyyy-MM-dd HH:mm" v-model="where.times" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker></div>
                    <!--<div style="width:160px;">
                        <el-select v-model="where.type" placeholder="选择类型">
                            <el-option label="全部" value=""></el-option>
                            <el-option label="未支付" value="NOPAY"></el-option>
                            <el-option label="已支付" value="WAITSEND"></el-option>
                            <el-option label="等待收货" value="WAITREC"></el-option>
                            <el-option label="等待评论" value="WAITCOMMENT"></el-option>
                            <el-option label="订单完成" value="COMPLETE"></el-option>
                            <el-option label="售后处理" value="REFUND"></el-option>
                            <el-option label="取消订单" value="CLOSE"></el-option>
                        </el-select>
                    </div>-->
                    <div><el-button icon="el-icon-search" @click="get_order_list()">条件筛选</el-button></div>
                </div>
                <div class="admin_main_block_right">
                    <div><el-button type="danger" icon="el-icon-delete" @click="del(select_id)">批量删除</el-button></div>
                </div>
                <!--<div class="admin_main_block_right">
                    <div><el-button icon="el-icon-back" @click="$router.go(-1)">返回</el-button></div>
                </div>-->
            </div>
            <div class="admin_table_main">
                <el-table :data="list" @selection-change="handleSelectionChange" >
                    <el-table-column type="selection"></el-table-column>
                    <!-- <el-table-column prop="id" label="#" fixed="left" width="70px"></el-table-column> -->
                    <el-table-column prop="id" label="#"  width="50px"></el-table-column>
                    <el-table-column label="创建者" width="150px">
                        <template slot-scope="scope">
                            <dl class="table_dl">
                                <dt><el-image style="width: 50px; height: 50px" :src="scope.row.F_VR_TOUX"><div slot="error" class="image-slot"><i class="el-icon-picture-outline"></i></div></el-image></dt>
                                <dd class="table_dl_dd_all" :title="scope.row.F_VR_NIC">{{ scope.row.F_VR_NIC }}</dd>
                            </dl>
                        </template>
                    </el-table-column>
                    <el-table-column label="作者" width="170px">
                        <template slot-scope="scope">
                            <dl class="table_dl">
                                <dt><el-image style="width: 50px; height: 50px" :src="scope.row.F_VR_ZUOZ_TOUX"><div slot="error" class="image-slot"><i class="el-icon-picture-outline"></i></div></el-image></dt>
                                <dd class="table_dl_dd_all" :title="scope.row.F_VR_ZUOZ_NIC">{{ scope.row.F_VR_ZUOZ_NIC }}</dd>
                            </dl>
                        </template>
                    </el-table-column>
                    <el-table-column prop="F_VR_RENZXX" label="认证信息" width="120px"></el-table-column>
                    <!--<el-table-column label="圈子头像" width="170px">
                        <template slot-scope="scope">
                            <dl class="table_dl">
                                <dt><el-image style="width: 50px; height: 50px" :src="scope.row.F_VR_QUANZTX"><div slot="error" class="image-slot"><i class="el-icon-picture-outline"></i></div></el-image></dt>
                                &lt;!&ndash;<dd class="table_dl_dd_all" :title="scope.row.F_VR_NIC">{{ scope.row.F_VR_NIC }}</dd>&ndash;&gt;
                            </dl>
                        </template>
                    </el-table-column>-->
                    <el-table-column prop="F_VR_JIANJ" label="简介" width="320px"></el-table-column>
                    <!--<el-table-column prop="F_VR_CHUC" label="出处"></el-table-column>-->
                    <!--<el-table-column label="订单状态">
                        <template slot-scope="scope">
                            <el-tag type="success" v-if="scope.row.cn_status=='订单完成'">{{scope.row.cn_status}}</el-tag>
                            <el-tag type="warning" v-else-if="scope.row.cn_status=='售后处理'">{{scope.row.cn_status}}</el-tag>
                            <el-tag v-else-if="scope.row.cn_status=='等待发货'">{{scope.row.cn_status}}</el-tag>
                            <el-tag type="danger" v-else-if="scope.row.cn_status=='等待支付'">{{scope.row.cn_status}}</el-tag>
                            <el-tag type="info" v-else>{{scope.row.cn_status}}</el-tag>
                        </template> 
                    </el-table-column>-->
                    <!-- <el-table-column label="PC推荐首页">
                        <template slot-scope="scope">
                            <div :class="scope.row.is_index==1?'green_round':'gray_round'" @click="goods_index(scope.row.id)"></div>
                        </template> 
                    </el-table-column> -->
                    <!--<el-table-column prop="total_price" label="价格"></el-table-column>-->
                    <el-table-column label="创建时间">
                        <template slot-scope="scope">
                            <div>{{scope.row.F_IN_CHUANGJSJ|formatDate}}</div>
                        </template> 
                    </el-table-column>
                    <!--<el-table-column label="操作" fixed="right" width="120px">
                        <template slot-scope="scope">
                            <el-button icon="el-icon-edit" @click="$router.push('/Admin/order/info/'+scope.row.id)">查看订单</el-button>
                            &lt;!&ndash; <el-button type="danger" icon="el-icon-delete" @click="del(scope.row.id)">删除</el-button> &ndash;&gt;
                        </template>
                    </el-table-column>-->
                    <el-table-column label="审核状态" width="100px">
                        <template slot-scope="scope">
                            <el-tag type="success" v-if="scope.row.F_TI_STATUS==1">通过</el-tag>
                            <el-tag type="warning" v-else-if="scope.row.F_TI_STATUS==2">审核不通过</el-tag>
                            <el-tag type="danger" v-else-if="scope.row.F_TI_STATUS==3">待审核</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" fixed="right" width="220px">
                        <template slot-scope="scope">
                            <el-button icon="el-icon-edit" @click="$router.push({name:'admin_zuoz_info',params:{id:scope.row.id}})">编辑</el-button>
                            <el-button icon="el-icon-edit" :disabled="scope.row.goods_verify==1 || scope.row.goods_verify==2" @click="verify_click(scope.row.id)">审核</el-button>
                            <!--<el-button icon="el-icon-edit" @click="$router.push('/Admin/order/info/'+scope.row.id)">查看订单</el-button>
                            &lt;!&ndash; <el-button type="danger" icon="el-icon-delete" @click="del(scope.row.id)">删除</el-button> &ndash;&gt;-->
                        </template>
                    </el-table-column>
                </el-table>
                <div class="admin_table_main_pagination">
                    <el-pagination @current-change="current_change" background layout="prev, pager, next,jumper,total" :total="total_data" :page-size="page_size" :current-page="current_page"></el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
      return {
          list:[],
          total_data:0, // 总条数
          page_size:20,
          current_page:1,
          select_id:'',
          goods_verify_count:0,
          where:{
              times:[],
              type:'',
              F_VR_NIC:'',
          },
      };
    },
    watch: {},
    computed: {},
    methods: {
        handleSelectionChange:function(e){
            let ids = [];
            e.forEach(v => {
                ids.push(v.id);
            });
            this.select_id = ids.join(',');
        },
        get_order_list:function(){
            let where = {};
            where.page = this.current_page;
            where.params = this.where;
            this.$get(this.$api.getZuozList,where).then(res=>{
                this.page_size = res.data.per_page;
                this.total_data = res.data.total;
                this.list = res.data.data;
                this.current_page = res.data.current_page;
            })

        },
        // 删除处理
        del:function(id){
             if(this.$isEmpty(id)){
                 return this.$message.error('请先选择删除的对象');
             }
             this.$post(this.$api.delZuoz,{id:id}).then(res=>{
                 if(res.code == 200){
                    this.get_order_list();
                     return this.$message.success("删除成功");
                 }else{
                     return this.$message.error("删除失败");
                 }
             });
        },
        verify_click:function(id){
            this.$confirm('此操作将控制作者是否通过审核, 是否继续?', '提示', {
                confirmButtonText: '同意',
                cancelButtonText: '拒绝',
                type: 'info'
            }).then(() => {
                this.$post(this.$api.zuozVerifyChange,{id:id,status:1}).then(()=>{
                    this.$message.success('成功审核');
                    this.get_order_list();
                });

            }).catch(() => {
                this.$post(this.$api.zuozVerifyChange,{id:id,status:2}).then(()=>{
                    this.$message.info('已拒绝');
                    this.get_order_list();
                });

            });
        },
        current_change:function(e){
            this.current_page = e;
            this.get_order_list();
        },
    },
    created() {
        this.get_order_list();
    },
    mounted() {}
};
</script>
<style lang="scss" scoped>
.table_dl_dd_all{
    height: 50px;
    overflow: hidden;
}
</style>